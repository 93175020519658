import { useState, useCallback } from "react";
import { InputVerificationCode } from "datamynt-ui-library";
import jwt_decode from "jwt-decode";
import {
  apiLoginOtp,
  apiLoginWithPassword,
  apiResendOtp,
  apiTwoFa,
  getUserProfile
} from "../../helpers/api";
import Themed from "../../components/Themed";
import ComponentWithErrorBoundary from "../../components/HOC/withErrorBoundary";
import useErrorHandling from "../../hooks/useErrorHandling";
import { useRouter } from "next/router";
import Notification from "../../components/Notification";
import LoginForm from "./LoginForm";

const Login = () => {
  const router = useRouter();
  const [captchaForReset, setCaptchaForReset] = useState(null);
  const [step, setStep] = useState("emailAndPassword");
  const [values, setValues] = useState(null);
  const { error, setError, withErrorHandling } = useErrorHandling();

  const SubMapper = {
    "login-otp-access": () => setStep("otpCode"),
    "2fa-access": () => setStep("2FAuth"),
    "unverified-email-access": () => {
      router.push("/login/email-verification");
    },
    "web-login": () => {
      router.push("/dashboard");
    },
    "pending-kyc-access": () => {
      router.push("/complete-register");
    },
    "pending-kyc-verification-access": () => setStep("pendingKyc"),
    "reset-password-access": () => {
      router.push(`/login/change-password`);
    }
  };

  const checkPage = () => {
    router.push("/login");
    document.title = "login";
  };

  const manageRouter = useCallback(async () => {
    router.beforePopState(checkPage);
    try {
      const { error } = await getUserProfile();

      if (!error) {
        router.push("/dashboard");
      }
    } catch (e) {
      const { sub } = jwt_decode(localStorage.getItem("token"));
      SubMapper[sub]();
    }
  }, [values]);

  const login = async values => {
    setValues(values);

    const { data, error } = await withErrorHandling(
      apiLoginWithPassword,
      values
    );

    if (error) {
      captchaForReset.reset();
      return false;
    }

    const { accessToken } = data;
    if (accessToken) {
      localStorage.setItem("token", accessToken);

      const { sub } = jwt_decode(accessToken);

      SubMapper[sub]();
    }
    captchaForReset.reset();
  };

  async function login2fa(code) {
    if (code.indexOf(" ") === -1) {
      const { data, error } = await withErrorHandling(
        apiTwoFa,
        JSON.stringify({ code })
      );

      if (error) {
        return;
      }

      localStorage.setItem("token", data["accessToken"]);
      manageRouter();
    }
  }

  async function loginOtp(code) {
    if (code.indexOf(" ") === -1) {
      const { data, error } = await withErrorHandling(
        apiLoginOtp,
        JSON.stringify({ code })
      );

      if (error) {
        return;
      }

      localStorage.setItem("token", data["accessToken"]);
      manageRouter();
    }
  }

  async function resendOtp() {
    await withErrorHandling(apiResendOtp);
  }

  const Steps = {
    emailAndPassword: (
      <LoginForm onSubmit={login} resetCaptcha={setCaptchaForReset} />
    ),
    otpCode: (
      <div className="flex flex-col items-center justify-center space-y-6">
        <h4 className="pb-4 text-primary text-center">
          Please enter the one-time login code we sent to your email to
          continue.
        </h4>
        <InputVerificationCode onCompleted={loginOtp} />
        <span className="text-center">Didn&apos;t get a code?</span>
        <button
          className="text-highlight"
          type="button"
          onClick={() => resendOtp()}
        >
          Resend
        </button>
      </div>
    ),
    "2FAuth": (
      <div className="flex flex-col items-center justify-center space-y-6">
        <h4 className="pb-4 text-primary text-center">
          Please enter the two factor authentication code to continue.
        </h4>
        <InputVerificationCode onCompleted={login2fa} />
        <span className="d-flex mt-2 text-center">
          You can use the app you selected to enable two factor authentication
          (i.e. Google Authenticator) to get your code.
        </span>
      </div>
    ),
    pendingKyc: (
      <div className="flex flex-col items-center justify-center space-y-6">
        <p>
          Your register info is being processed by our team, we will reach back
          to you as soon as we have finished our KYC process.
        </p>
      </div>
    )
  };

  return (
    <div
      className={`bg-light-transparent flex flex-col w-screen h-screen p-8 dark:bg-gray-dashboard text-black dark:text-white justify-center items-center`}
    >
      {!(typeof window === "undefined") && Steps[step]}
      {error && (
        <Notification
          onClose={() => setError(null)}
          closeAfter={2500}
          type="error"
          text={error}
        />
      )}
    </div>
  );
};

const ThemedLogin = () => (
  <Themed>
    <Login />
  </Themed>
);

export default ComponentWithErrorBoundary(ThemedLogin);
