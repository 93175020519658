import { NotificationsBasic } from "datamynt-ui-library";
import { useEffect } from "react";

const Notification = ({ type, text, closeAfter, onClose }) => {
  useEffect(() => {
    if (closeAfter) {
      setTimeout(() => {
        onClose && onClose();
      }, closeAfter);
    }
  }, [closeAfter, onClose]);

  return (
    <div className="z-50 w-full fixed flex flex-col items-center justify-center left-0 bottom-4">
      <div className="w-1/3">
        <NotificationsBasic type={type}>
          <p>{text || "Error"}</p>
        </NotificationsBasic>
      </div>
    </div>
  );
};

export default Notification;
