import { ButtonSimpleRounded } from "datamynt-ui-library";

const ErrorFallback = ({ error, resetErrorBoundary }) => (
  <div className="w-full h-screen dark:bg-dark-base bg-light-transparent flex items-center justify-center flex-col space-y-12 dark:text-white">
    <p>{error.message}</p>
    <ButtonSimpleRounded label="Go back to app" onClick={resetErrorBoundary} />
  </div>
);

export default ErrorFallback;
