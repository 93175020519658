import { ButtonSimpleRounded, InputForm, Logo } from "datamynt-ui-library";
import { getCurrentTheme } from "../../helpers/theme";
import { useFormik } from "formik";
import * as Yup from "yup";
import Recaptcha from "reaptcha";
import Link from "next/link";
import FormikErrorMsgs from "../../components/formikErrorMsgs";

const Styles = {
  errorMsg: "text-red-primary text-sm"
};

const validationSchema = Yup.object().shape({
  email: Yup.string(FormikErrorMsgs.invalidEmail)
    .max(255)
    .required(FormikErrorMsgs.required),
  password: Yup.string().required(FormikErrorMsgs.required).max(24),
  captcha: Yup.string().required(FormikErrorMsgs.required)
});

const LoginForm = ({ onSubmit, resetCaptcha }) => {
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      captcha: ""
    },
    validateOnChange: false,
    onSubmit,
    validationSchema,
    enableReinitialize: true
  });

  return (
    <form className="flex flex-col items-center w-3/3 sm:w-2/3 lg:w-2/5 p-8">
      <div className="pb-8">
        <Logo
          type="DmDefaultFullColor"
          color={getCurrentTheme() === "dark" ? "#ffffff" : "#3D8ECD"}
        />
      </div>
      <div className="space-y-4 w-full mb-3">
        <div>
          <InputForm
            withBg
            roundedFull
            onChange={formik.handleChange}
            name="email"
            type="email"
            id="userEmail"
            placeholder="Your email*"
          />
          <p className={formik.errors["email"] ? Styles.errorMsg : ""}>
            {formik.errors["email"]}
          </p>
        </div>
        <div>
          <InputForm
            withBg
            roundedFull
            onChange={formik.handleChange}
            name="password"
            id="userPassword"
            type="password"
            placeholder="Password*"
          />
          <p className={formik.errors["password"] ? Styles.errorMsg : ""}>
            {formik.errors["password"]}
          </p>
        </div>
      </div>
      <div className="text-xs w-full mt-0">
        <div className="text-highlight text-right pr-2">
          <Link href="/login/forgot-password">
            <a>Forgot your password?</a>
          </Link>
        </div>
      </div>
      <div className="text-xs space-y-4 my-8">
        <div className="pl-2 leading-5">
          By logging in, I accept{" "}
          <Link href="/terms">
            <a className="text-highlight" target="_blank">
              Data&nbsp;Mynt&apos;s Terms of Use
            </a>
          </Link>
          &nbsp;and{" "}
          <Link href="/privacypolicy">
            <a className="text-highlight" target="_blank">
              Privacy Policy.
            </a>
          </Link>
        </div>
      </div>

      <div className="w-full flex flex-col items-center mb-6">
        <Recaptcha
          theme={getCurrentTheme()}
          sitekey={
            process.env.NEXT_PUBLIC_CONTEXT === "production"
              ? process.env.NEXT_PUBLIC_RECAPTCHA_KEY
              : process.env.NEXT_PUBLIC_RECAPTCHA_TEST_KEY
          }
          onVerify={captcha => formik.setFieldValue("captcha", captcha)}
          ref={resetCaptcha}
        />
        <p className={formik.errors["captcha"] ? Styles.errorMsg : ""}>
          {formik.errors["captcha"]}
        </p>
      </div>

      <div data-cy="loginSubmitBtn" className="w-full">
        <ButtonSimpleRounded
          grow={true}
          size="small"
          onClick={formik.handleSubmit}
          label="Login"
        />
      </div>

      <div className="text-xs mt-8">
        Don&apos;t have an account?
        <span className="text-highlight">
          <Link href="/contactus">
            <a>&nbsp; Click here to contact us</a>
          </Link>
        </span>
      </div>
    </form>
  );
};

export default LoginForm;
