import { withErrorBoundary } from "react-error-boundary";
import ErrorFallback from "../ErrorFallback";

const ComponentWithErrorBoundary = ComponentThatMayError =>
  withErrorBoundary(ComponentThatMayError, {
    FallbackComponent: ErrorFallback,
    onReset: () => {
      window.location.search = "/app";
    },
    onError(error) {
      typeof window !== undefined && window.Rollbar?.error(error);
    }
  });

export default ComponentWithErrorBoundary;
